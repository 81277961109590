import * as L from 'leaflet';
import "leaflet.gridlayer.googlemutant"
import "leaflet.locatecontrol"
import "leaflet.heat"

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    shadowUrl: '/images/maps/marker-shadow.png',
    // iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    // iconUrl: require('leaflet/dist/images/marker-icon.png'),
    iconUrl: '/images/maps/HB_ACLS_ChartSelect_Pin_Down.png',
    iconRetinaUrl: '/images/maps/HB_ACLS_ChartSelect_Pin_Down.png'
});

export const osm = L.tileLayer(
    'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
)

// valid values are 'roadmap', 'satellite', 'terrain' and 'hybrid'
export const googleRoadmap = L.gridLayer.googleMutant({type: "roadmap"})
export function getGoogleHybrid(){
    return L.gridLayer.googleMutant({type: "hybrid"});
}
export const googleHybrid = getGoogleHybrid()
export const googleTerrain = L.gridLayer.googleMutant({type: "terrain"})
export const googleSatellite = L.gridLayer.googleMutant({type: "satellite"})

export const globalBaseUrl = '/api/social/community';
export const userBaseUrl = '/api/social/user';
export const zoomOptions = {
    minZoom: 14,
    maxZoom: 18
}

